import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { GoBackIcon } from '../../../ConnectHeader/GoBackIcon'
import { Title } from '../../../Title'
import { Props } from './IniciadorTerms.types'

import './IniciadorTerms.css'

const IniciadorTerms = ({ onClose }: Props) => {
  const { t } = useTranslation()
  return (
    <div className={'IniciadorTerms'}>
      <div className="container">
        <GoBackIcon onClick={onClose} />
        <Title>{t('iniciador-terms.title')}</Title>
        <Trans
          i18nKey="iniciador-terms.terms"
          components={{
            a: (
              // eslint-disable-next-line jsx-a11y/anchor-has-content
              <a
                target="_blank"
                href="https://iniciador.com.br/iniciador-politica-privacidade-termos.pdf"
                rel="noreferrer"
              />
            ),
            br: <br />,
          }}
        />
      </div>
    </div>
  )
}

export default React.memo(IniciadorTerms)
